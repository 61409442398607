import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationDot,
  faPhone,
  faClock,
} from '@fortawesome/free-solid-svg-icons'
import { faLine, faInstagram } from '@fortawesome/free-brands-svg-icons'

import Layout from '../layouts/Layout'
import shop1 from '../images/shop_01.jpeg'
import SEO from '../components/SEO'

const Shop = () => {
  return (
    <Layout>
      <h1 className="text-4xl text-center mb-10 font-black">店舗情報</h1>
      <div className="bg-white rounded-md p-6 mb-5">
        <h2 className="text-2xl mb-5 font-black">
          【とんかつの店　馬酔木 (あしび)】
        </h2>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 ">
          <img
            className="w-full lg:w-2/5 rounded-md"
            src={shop1}
            alt="ashibi-shop"
          />
          <div className="leading-7 text-lg">
            <div className="flex items-start content mb-4">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="mr-2 mt-1 w-5 h-5 text-main"
              />
              <a
                href="https://goo.gl/maps/nL4e9hQRAUmiM8aJA"
                target="_blank"
                className="underline"
              >
                〒224-0065 横浜市都筑区高山 14-5 エスパシオ高山
              </a>
            </div>
            <div className="flex items-center content mb-4">
              <FontAwesomeIcon
                icon={faPhone}
                className="mr-2 text-main w-5 h-5"
              />
              <a href="tel:045-941-9544" className="underline">
                045-941-9544
              </a>
            </div>
            <div className="flex items-start content mb-4">
              <FontAwesomeIcon
                icon={faClock}
                className="mr-2 mt-1 w-5 h-5 text-main"
              />
              <div>
                11:30~14:00ラストオーダー
                <br />
                15:00 閉店
                <br />
                17:30~20:30ラストオーダー
                <br />
                21:30閉店
              </div>
            </div>
            <div className="flex items-center content mb-8">
              <div className="flex ml-6 space-x-2">
                <a href="https://lin.ee/FzdFqL1" target="_blank">
                  <img
                    src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                    alt="友だち追加"
                    className="h-10"
                  />
                </a>
                <a href="https://page.line.me/ashibiyokohama" target="_blank">
                  <FontAwesomeIcon
                    className="w-10 h-10 text-[#00b901]"
                    icon={faLine}
                  />
                </a>
                <a href="https://www.instagram.com/ashibi1040/" target="_blank">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="w-10 h-10 text-[#405DE6]"
                  />
                </a>
              </div>
            </div>
            <div>
              馬酔木の情報とサービスは
              <a
                href="https://tabelog.com/kanagawa/A1402/A140203/14004365/"
                target="_blank"
                className="underline"
              >
                こちら
              </a>
              も御覧ください。
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-md p-6 mb-5">
        <h2 className="text-2xl mb-5 font-black">座席</h2>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 mb-4">
          <div className="leading-7 text-lg">
            <ul className="list-disc ml-3 mb-2">
              <li>カウンター：３席</li>
              <li>4名掛けテーブル：3席</li>
              <li>2名掛けテーブル：１席</li>
              <li>6名お座敷：１席</li>
            </ul>
            ※掘りごたつ式のお座敷ですので御年配の方
            もゆっくりお食事ができます。テーブルを含め早めの御予約をお願いします。
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="店舗情報｜とんかつの店 馬酔木 横浜市都筑区" />
)

export default Shop
